import { MovableElementProperties } from "@tikifu/shared/types";
import { debouncedUpdateElement } from "../../../services/elements";
import { Element } from "./Element";

export abstract class MovableElement extends Element {
  abstract state: Element["state"] & {
    x: number;
    y: number;
    dragging: boolean;
  };

  abstract properties: MovableElementProperties;

  containsPoint(x: number, y: number, padding = 0): boolean {
    this.state.width += padding;
    this.state.height += padding;
    const r =
      x >= this.properties.x - this.state.width / 2 &&
      x <= this.properties.x + this.state.width / 2 &&
      y >= this.properties.y - this.state.height / 2 &&
      y <= this.properties.y + this.state.height / 2;
    this.state.width -= padding;
    this.state.height -= padding;
    return r;
  }

  setProperties(properties: Partial<this["properties"]>) {
    this.properties = { ...this.properties, ...properties };
    if (this.id) {
      debouncedUpdateElement(this.id, {
        properties,
      });
    }
  }
}
