import { ElementFunctionNames } from "../../../types";
import { isTextElement } from "../util/guards";
import { CanvasElement } from "./CanvasElement";

export class CanvasEditor {
  canvases: CanvasElement[];
  lastClickedElement: symbol | null;
  currentCanvas: CanvasElement | null;
  currentAction: null | "left" | "right" | "top" | "bottom" | "select" | "move";

  constructor() {
    this.canvases = [];
    this.lastClickedElement = null;
    this.currentCanvas = null;
    this.currentAction = null;
  }

  reset() {
    this.canvases = [];
    this.lastClickedElement = null;
    this.currentCanvas = null;
    this.currentAction = null;
  }

  addCanvas(canvas: CanvasElement) {
    this.canvases.push(canvas);
  }

  removeCanvas(canvas: CanvasElement) {
    this.canvases = this.canvases.filter((c) => c !== canvas);
  }

  draw() {
    for (const canvas of this.canvases) {
      canvas.draw();
    }
  }

  switchContext(canvas: CanvasElement | null) {
    if (this.currentCanvas !== canvas) {
      this.currentCanvas = canvas;
    }
  }

  async callSelected(func: ElementFunctionNames, args: any[]) {
    for (const canvas of this.canvases) {
      const value = canvas[func as keyof typeof canvas];
      if (canvas.state.selected && value && typeof value === "function") {
        // @ts-expect-error Impossible to make TypeScript work here
        // eslint-disable-next-line
        await Promise.resolve(value.apply(canvas, args));
      }
      for (const element of canvas.elements) {
        const value = element[func as keyof typeof element];
        if (element.state.selected && value && typeof value === "function") {
          // @ts-expect-error Impossible to make TypeScript work here
          // eslint-disable-next-line
          await Promise.resolve(value.apply(element, args));
        }
      }
    }
    this.draw();
  }

  clearSelections() {
    this.canvases.forEach((canvas) => {
      canvas.state.selected = false;
      canvas.elements.forEach((element) => {
        element.state.selected = false;
      });
    });
  }

  findElement(x: number, y: number) {
    const currentCanvas = this.currentCanvas;
    if (!currentCanvas) return null;

    return currentCanvas.elements
      .toReversed()
      .find(
        (element) =>
          element.containsPoint(
            x,
            y,
            0.03 * (currentCanvas.ref.current?.width ?? 0),
          ) && element.properties.active,
      );
  }

  handleClickOutside(item: HTMLElement) {
    const isClickOutside = this.canvases.every(
      (data) => data.ref.current && !data.ref.current.contains(item),
    );
    if (isClickOutside) {
      this.lastClickedElement = null;
      this.currentAction = null;
      this.clearSelections();
      this.draw();
    }
  }

  handleMouseDown(x: number, y: number, selectMultiple: boolean) {
    const clickedElement = this.findElement(x, y);

    if (!clickedElement) {
      this.lastClickedElement = null;
    }

    if (!selectMultiple || !clickedElement) {
      this.clearSelections();
    }

    if (clickedElement) {
      clickedElement.state.selected = true;
    } else if (this.currentCanvas) {
      this.currentCanvas.state.selected = true;
    }

    this.draw();
  }

  handleMouseMove(x: number, y: number): typeof this.currentAction {
    if (!this.currentCanvas) return null;
    const hoveredElement = this.findElement(x, y);

    if (!hoveredElement) {
      if (this.currentCanvas.state.selected) {
        this.currentAction = "move";
      } else {
        this.currentAction = "select";
      }
      return this.currentAction;
    }

    const threshold = 0.03 * (this.currentCanvas.ref.current?.width ?? 0);

    const leftEdge =
      hoveredElement.properties.x - hoveredElement.state.width / 2;
    const rightEdge =
      hoveredElement.properties.x + hoveredElement.state.width / 2;
    const topEdge =
      hoveredElement.properties.y - hoveredElement.state.height / 2;
    const bottomEdge =
      hoveredElement.properties.y + hoveredElement.state.height / 2;

    if (Math.abs(x - leftEdge) <= threshold) {
      this.currentAction = "left";
    } else if (Math.abs(x - rightEdge) <= threshold) {
      this.currentAction = "right";
    } else if (Math.abs(y - topEdge) <= threshold) {
      this.currentAction = "top";
    } else if (Math.abs(y - bottomEdge) <= threshold) {
      this.currentAction = "bottom";
    } else if (hoveredElement.state.selected) {
      this.currentAction = "move";
    } else {
      this.currentAction = "select";
    }

    return this.currentAction;
  }

  handleMouseDrag(x: number, y: number) {
    if (!this.currentCanvas) return;

    const canvasRef = this.currentCanvas.ref.current;

    if (!canvasRef) return;

    const middleX = canvasRef.width / 2;

    for (const canvas of this.canvases) {
      for (const element of canvas.elements) {
        const leftEdge = element.properties.x - element.state.width / 2;
        const rightEdge = element.properties.x + element.state.width / 2;
        const topEdge = element.properties.y - element.state.height / 2;
        const bottomEdge = element.properties.y + element.state.height / 2;
        const isNearCenter = Math.abs(element.state.x - middleX) < 20;

        if (element.state.selected) {
          if (this.currentAction === "left") {
            const newWidth = rightEdge - x;
            if (newWidth > 5) {
              element.state.width = newWidth;
              element.state.x = x + newWidth / 2;
              element.properties.x = element.state.x;
            }
          } else if (this.currentAction === "right") {
            const newWidth = x - leftEdge;
            if (newWidth > 5) {
              element.state.width = newWidth;
              element.state.x = leftEdge + newWidth / 2;
              element.properties.x = element.state.x;
            }
          } else if (this.currentAction === "top") {
            const newHeight = bottomEdge - y;
            if (newHeight > 5) {
              element.state.height = newHeight;
              element.state.y = y + newHeight / 2;
              element.properties.y = element.state.y;
            }
          } else if (this.currentAction === "bottom") {
            const newHeight = y - topEdge;
            if (newHeight > 5) {
              element.state.height = newHeight;
              element.state.y = topEdge + newHeight / 2;
              element.properties.y = element.state.y;
            }
          } else {
            element.state.x = x;
            element.state.y = y;
            element.properties.y = element.state.y;
            if (!isNearCenter) {
              element.properties.x = element.state.x;
            } else {
              element.properties.x = middleX;
            }
          }
          if (isTextElement(element)) {
            element.state.editable = false;
          }
          element.state.dragging = true;
          if (element.id) {
            element.setProperties({
              x: element.properties.x,
              y: element.properties.y,
            });
          }
        }
      }
    }
    if (this.currentAction === "select") {
      this.currentAction = "move";
    }
    this.draw();
  }

  handleMouseUp(x: number, y: number) {
    const clickedElement = this.findElement(x, y);

    if (
      this.currentAction === "select" &&
      (clickedElement?.state.selected || this.currentCanvas?.state.selected)
    ) {
      this.currentAction = "move";
    }

    if (!clickedElement) return;

    if (
      clickedElement.tempId === this.lastClickedElement &&
      isTextElement(clickedElement) &&
      !clickedElement.state.dragging
    ) {
      clickedElement.state.editable = true;
    }

    for (const canvas of this.canvases) {
      for (const element of canvas.elements) {
        element.state.dragging = false;
      }
    }

    this.lastClickedElement = clickedElement.tempId;
    this.draw();
  }
}
