import { GenerateJob, Prompt, RunPodJob } from "@tikifu/shared/types";
import { API_URL } from "../util/config";
import { post } from "../util/req";

const generateImage = (body: Prompt) =>
  post<RunPodJob>(`${API_URL}/generate/image`, body);

const checkGenerationStatus = (id: string) =>
  post<GenerateJob>(`${API_URL}/generate/status/${id}`);

export { generateImage, checkGenerationStatus };
