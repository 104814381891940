import React, { useState } from "react";

interface TooltipProps {
  text: string;
  position?: "top" | "right" | "bottom" | "left";
  children: React.ReactNode;
  disabled?: boolean;
}

const Tooltip: React.FC<TooltipProps> = ({
  text,
  position = "top",
  children,
  disabled = false,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const getTooltipPosition = () => {
    switch (position) {
      case "top":
        return "bottom-full left-1/2 transform -translate-x-1/2 mb-2";
      case "right":
        return "top-1/2 left-full transform -translate-y-1/2 ml-2";
      case "bottom":
        return "top-full left-1/2 transform -translate-x-1/2 mt-2";
      case "left":
        return "top-1/2 right-full transform -translate-y-1/2 mr-2";
      default:
        return "bottom-full left-1/2 transform -translate-x-1/2 mb-2";
    }
  };

  const handleChildClick = () => {
    setIsVisible(false);
  };

  return (
    <div
      className="relative flex items-center justify-center"
      onMouseEnter={() => {
        setIsVisible(true);
      }}
      onMouseLeave={() => {
        setIsVisible(false);
      }}
    >
      <div
        role="presentation"
        onClick={handleChildClick}
        className="cursor-pointer"
      >
        {children}
      </div>
      {!disabled && isVisible && (
        <div
          className={`absolute z-[1001] ${getTooltipPosition()} bg-[#292929] text-white text-sm px-3 py-2 rounded shadow-lg whitespace-nowrap`}
        >
          {text}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
