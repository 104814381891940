import { Project } from "@tikifu/shared/types";
import { useEffect, useRef } from "react";
import { isImageElement, isTextElement } from "./util/guards";
import { generateSlideshows, loadProject } from "./util/helpers";
import { CanvasEditor } from "./elements/CanvasEditor";

const PlainEditor = () => {
  const canvasRef = useRef<CanvasEditor>(new CanvasEditor());

  useEffect(() => {
    window.loadProject = (project: Project) =>
      loadProject(canvasRef, project, true);

    window.createCombination = (index: number) => {
      const slideshows = generateSlideshows(canvasRef);
      const nextSlideshow = slideshows[index];
      if (index >= slideshows.length) {
        return Promise.resolve(null);
      }
      return window.getSlideshowAsBlobs(nextSlideshow);
    };

    window.getSlideshowAsBlobs = async (
      slideshow: {
        image: string;
        texts: string[];
      }[],
    ) => {
      const imagePromises: Promise<void>[] = [];

      slideshow.forEach((slide, index) => {
        canvasRef.current.canvases[index].elements
          .filter(isTextElement)
          .forEach((obj, i) => {
            if (!isTextElement(obj)) return;
            obj.properties.text = slide.texts[i];
          });
        const bgImg =
          canvasRef.current.canvases[index].elements.find(isImageElement);
        if (bgImg) {
          const imageIndex = bgImg.properties.sources.findIndex(
            (source) => source === slide.image,
          );
          bgImg.properties.currentSource = imageIndex;
          imagePromises.push(
            bgImg.load().then(() => {
              canvasRef.current.canvases[index].draw();
            }),
          );
        }
      });

      await Promise.all(imagePromises);

      const blobs = await Promise.all(
        canvasRef.current.canvases.map(
          (state) =>
            new Promise<Blob>((resolve, reject) => {
              state.ref.current?.toBlob((b) => {
                if (!b) {
                  reject(
                    new Error("One of the canvas refs is possibly undefined"),
                  );
                  return;
                }
                resolve(b);
              }, "image/jpeg");
            }),
        ),
      );

      return blobs;
    };

    return () => {
      const container = document.getElementById("canvases");
      if (!container) return;
      canvasRef.current.canvases.forEach((canvas) => {
        if (!canvas.ref.current) return;
        container.removeChild(canvas.ref.current);
      });
    };
  }, []);

  return null;
};

export default PlainEditor;
