import { useCallback, useState } from "react";
import JSZip from "jszip";
import saveAs from "file-saver";
import { isImageElement, isTextElement } from "../Editor/util/guards";
import { generateSlideshows } from "../Editor/util/helpers";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import { useAppStore } from "../../hooks/useAppStore";
import { CanvasRef } from "../../types";
import NumberInput from "../../components/NumberInput";

interface DownloadProps {
  canvasRef: CanvasRef;
}

const Download = ({ canvasRef }: DownloadProps) => {
  const { modal, setModal } = useAppStore();
  const [downloadAmount, setDownloadAmount] = useState(1);

  const maxCombinations = canvasRef.current.canvases.reduce(
    (combinations, data) => {
      const bgImg = data.elements.find(isImageElement);
      if (!bgImg) return combinations;
      const variations =
        bgImg.properties.sources.length *
        data.elements.reduce((amount, obj) => {
          if (!isTextElement(obj)) return amount;
          return obj.properties.texts.length * amount;
        }, 1);
      return combinations * variations;
    },
    1,
  );

  const handleDownloadSlideshows = useCallback(
    async (downloadAmount: number) => {
      try {
        const zip = new JSZip();
        let combinationIndex = 0;

        const slideshows = generateSlideshows(canvasRef, downloadAmount);

        for (const slideshow of slideshows) {
          const imagePromises: Promise<void>[] = [];

          slideshow.forEach((slide, index) => {
            canvasRef.current.canvases[index].elements.forEach((element) => {
              element.state.selected = false;
            });
            canvasRef.current.canvases[index].elements
              .filter(isTextElement)
              .forEach((element, i) => {
                element.properties.text = slide.texts[i];
              });
            const bgImg =
              canvasRef.current.canvases[index].elements.find(isImageElement);
            if (bgImg) {
              const imageIndex = bgImg.properties.sources.findIndex(
                (source) => source === slide.image,
              );
              bgImg.properties.currentSource = imageIndex;
              imagePromises.push(
                bgImg.load().then(() => {
                  canvasRef.current.canvases[index].draw();
                }),
              );
            }
          });

          await Promise.all(imagePromises);

          const folder = zip.folder(`${combinationIndex}`);

          const capturePromises = canvasRef.current.canvases.map((data, i) => {
            return new Promise<void>((resolve) => {
              if (!folder || !data.ref.current) return;
              data.ref.current.toBlob((blob) => {
                if (!blob) return;
                folder.file(`${i}.jpeg`, blob);
                resolve();
              });
            });
          });
          await Promise.all(capturePromises);
          combinationIndex++;
        }

        const content = await zip.generateAsync({ type: "blob" });
        saveAs(content, "slideshows.zip");
      } catch (e) {
        console.error("Error downloading slideshows", e);
      }
    },
    [],
  );

  return (
    <Modal
      open={modal === "download"}
      onClose={() => {
        setModal(null);
      }}
    >
      <h2 className="text-xl font-semibold">Download slideshows</h2>
      <p>Choose how many combinations of slideshows you want to download.</p>
      <NumberInput
        id="slider"
        step={1}
        min={1}
        max={maxCombinations}
        defaultValue={1}
        onChange={(value) => {
          setDownloadAmount(value);
        }}
        minusTooltip="Decrease combinations"
        plusTooltip="Increase combinations"
        inputTooltip="Combinations"
        decimals={0}
      />
      <Button
        className="w-full"
        color="white"
        onClick={() => void handleDownloadSlideshows(downloadAmount)}
      >
        Download
      </Button>
    </Modal>
  );
};

export default Download;
