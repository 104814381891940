import { useParams } from "react-router-dom";
import { useState } from "react";
import { useAppStore } from "../../hooks/useAppStore";
import { uploadTiktokSlideshow } from "../../services/upload";
import Button from "../../components/Button";
import Modal from "../../components/Modal";

const Upload = () => {
  const { selectedUser, modal, setModal } = useAppStore();
  const { id: projectId } = useParams();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const handleTiktokUpload = async () => {
    if (!selectedUser || !projectId) return;

    try {
      await uploadTiktokSlideshow(selectedUser, {
        projectId: Number(projectId),
        title,
        description,
      });
    } catch (e) {
      console.error("Error uploading slideshow to TikTok", e);
    }
  };

  return (
    <Modal
      open={modal === "upload"}
      onClose={() => {
        setModal(null);
      }}
    >
      <h2 className="text-xl font-semibold">Upload slideshow</h2>
      <p>
        Uploading a slideshow will upload is draft that is ready to be posted
        from the TikTok app.
      </p>
      <div className="flex flex-col gap-3 w-full">
        <input
          placeholder="Title"
          type="text"
          className="bg-transparent text-white border border-white py-1 px-3 rounded cursor-pointer text-base"
          value={title}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
        />
      </div>
      <div className="flex flex-col gap-3 w-full">
        <textarea
          placeholder="Description"
          className="bg-transparent text-white border border-white py-1 px-3 rounded cursor-pointer text-base"
          value={description}
          onChange={(e) => {
            setDescription(e.target.value);
          }}
          rows={5}
        />
      </div>
      <Button
        className="w-full"
        access="pro"
        provider="tiktok"
        color="white"
        onClick={() => void handleTiktokUpload()}
      >
        Upload
      </Button>
    </Modal>
  );
};

export default Upload;
