import { ReactNode, useState } from "react";

interface ToggleProps {
  defaultValue?: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  children: ReactNode;
}

const Toggle = ({ defaultValue, onClick, children }: ToggleProps) => {
  const [toggled, setToggled] = useState(!!defaultValue);

  return (
    <button
      className={`${toggled ? "bg-white text-gray-900" : ""} hover:bg-white hover:text-gray-900 flex items-center justify-center rounded w-[34px] h-[34px]`}
      onClick={(e) => {
        setToggled((prev) => !prev);
        onClick(e);
      }}
    >
      {children}
    </button>
  );
};

export default Toggle;
