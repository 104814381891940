import React, { ReactNode } from "react";
import { Crown } from "@phosphor-icons/react";
import { useAppStore } from "../hooks/useAppStore";

interface BaseButtonProps {
  color?: "white" | "black";
  size?: "large";
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  children: ReactNode;
}

interface ButtonProps extends BaseButtonProps {
  access?: "pro" | null;
  provider?: "tiktok" | null;
}

const UserButton = ({
  access = null,
  provider = null,
  children,
  className,
  onClick,
  ...props
}: ButtonProps) => {
  const { user, selectedUser, setModal } = useAppStore();

  const accessBlocked = access === "pro" && !user?.pro;
  const providerBlocked = provider === "tiktok" && !selectedUser;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (accessBlocked) {
      setModal("upgrade");
    } else if (providerBlocked) {
      setModal("tiktok");
    } else if (onClick) {
      onClick(event);
    }
  };

  return (
    <BaseButton
      onClick={handleClick}
      className={`flex flex-row gap-2 items-center ${accessBlocked ? "justify-between" : "justify-center"} ${className}`}
      {...props}
    >
      {accessBlocked ? <Crown className="text-orange-400" size={24} /> : null}
      {children}
      {accessBlocked ? <Crown className="text-orange-400" size={24} /> : null}
    </BaseButton>
  );
};

const BaseButton: React.FC<BaseButtonProps> = ({
  color = "white",
  className = "",
  onClick,
  children,
  size,
}) => {
  const baseClasses = `${size ? "px-2 py-1" : "px-2 py-1"} rounded transition duration-300 ease-in-out font-medium outline-none flex flex-row justify-center items-center`;
  const colorClasses =
    color === "white"
      ? "text-white border border-white hover:bg-white hover:text-gray-900"
      : "text-black border border-black hover:bg-black hover:text-white";

  return (
    <button
      className={`${baseClasses} ${colorClasses} ${className}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

const Button = ({ access, provider, ...props }: ButtonProps) => {
  if (access || provider) {
    return <UserButton access={access} provider={provider} {...props} />;
  }

  return <BaseButton {...props} />;
};

export default Button;
