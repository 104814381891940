import { useEffect, useRef, useState } from "react";
import { Coin, Crown } from "@phosphor-icons/react";
import { createPaymentLink } from "../services/payments";
import Button from "../components/Button";
import { TIKTOK_AUTH_URL } from "../util/config";
import Modal from "../components/Modal";
import { useAppStore } from "../hooks/useAppStore";
import SelectableBlocks from "../components/SelectableBlocks";

const UpgradeModalContent = () => {
  const [paymentLink, setPaymentLink] = useState("");

  useEffect(() => {
    const initialize = async () => {
      try {
        const { url } = await createPaymentLink({ product: "tikifu_pro" });
        setPaymentLink(url);
      } catch (e) {
        console.error("Error getting payment link", e);
      }
    };

    void initialize();
  }, []);

  return (
    <>
      <h2 className="text-xl font-semibold text-center">Get Tikifu Pro</h2>
      <div className="flex flex-row gap-2 justify-center">
        <Crown className="text-orange-400" size={24} />
        Unlock all Tikifu features for $15 a month.
        <Crown className="text-orange-400" size={24} />
      </div>
      <Button
        color="black"
        onClick={() => {
          window.location.href = paymentLink;
        }}
      >
        Get Pro
      </Button>
    </>
  );
};

const AddTiktokAccountModalContent = () => {
  return (
    <>
      <h2 className="text-xl font-semibold text-center text-white">
        Add TikTok account
      </h2>
      <p className="text-white">
        Please logout or login to the account you&apos;d like to add on TikTok
        before clicking the button below.
      </p>
      <Button
        className="w-full"
        color="white"
        onClick={() => {
          window.location.href = TIKTOK_AUTH_URL;
        }}
      >
        Add account
      </Button>
    </>
  );
};

const PRODUCT_MAP = {
  tokens_100: {
    price: "$10",
    value: "tokens_100",
    item: (
      <>
        <Coin className="w-5 h-5" />
        100
      </>
    ),
  },
  tokens_250: {
    price: "$22.99",
    value: "tokens_250",
    item: (
      <>
        <Coin className="w-5 h-5" />
        250
      </>
    ),
  },
  tokens_500: {
    price: "$44.99",
    value: "tokens_500",
    item: (
      <>
        <Coin className="w-5 h-5" />
        500
      </>
    ),
  },
};

type TokenProduct = "tokens_100" | "tokens_250" | "tokens_500";

const BuyTokensModalContent = () => {
  const [product, setProduct] = useState<TokenProduct>();
  const paymentLinks = useRef<Partial<Record<TokenProduct, string>>>({});

  const handleCoinsAmountChange = async (product: TokenProduct) => {
    try {
      setProduct(product);
      if (product in paymentLinks.current) return;
      const paymentLink = await createPaymentLink({
        product,
      });
      paymentLinks.current[product] = paymentLink.url;
    } catch (e) {
      console.error("Error creating tokens payment link", e);
    }
  };

  return (
    <>
      <h2 className="text-xl font-semibold text-white">Buy tokens</h2>
      <p className="text-white">
        Tokens can be used for generating new slides with AI. Choose how many
        tokens you want to buy.
      </p>
      <SelectableBlocks
        options={Object.values(PRODUCT_MAP)}
        onChange={(values) =>
          void handleCoinsAmountChange(values[0] as TokenProduct)
        }
      />
      <Button
        className="w-full"
        onClick={() => {
          if (product && paymentLinks.current[product]) {
            window.location.href = paymentLinks.current[product];
          }
        }}
        color="white"
      >
        {!product
          ? "Select amount"
          : `Buy ${product.split("_")[1]} tokens for ${PRODUCT_MAP[product].price}`}
      </Button>
    </>
  );
};

const MODAL_TYPES = ["tiktok", "upgrade", "tokens"];

const Modals = () => {
  const { modal, setModal } = useAppStore();

  return (
    <Modal
      open={modal ? MODAL_TYPES.includes(modal) : false}
      onClose={() => {
        setModal(null);
      }}
    >
      {modal === "tiktok" ? (
        <AddTiktokAccountModalContent />
      ) : modal === "upgrade" ? (
        <UpgradeModalContent />
      ) : modal === "tokens" ? (
        <BuyTokensModalContent />
      ) : null}
    </Modal>
  );
};

export default Modals;
