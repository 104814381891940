import { useEffect, useState } from "react";
import { CanvasElement } from "../elements/CanvasElement";
import { isImageElement } from "../util/guards";
import useEditorStore from "../hooks/useEditorStore";
import { CanvasRef } from "../../../types";
import Canvas from "./Canvas";

interface CanvasArrangerProps {
  canvasRef: CanvasRef;
}

const CanvasArranger = ({ canvasRef }: CanvasArrangerProps) => {
  const { canvasArrangerRenderCount, projectLoading } = useEditorStore();
  const [draggedCanvas, setDraggedCanvas] = useState<symbol | null>(null);
  const [dragOffset, setDragOffset] = useState<number>(0);
  const [positions, setPositions] = useState<number[]>([]);
  const [transforms, setTransforms] = useState<Record<symbol, number>>({});
  const [transitions, setTransitions] = useState<Record<symbol, boolean>>({});

  useEffect(() => {
    if (!projectLoading) {
      canvasRef.current.draw();
    }
  }, [canvasArrangerRenderCount, projectLoading]);

  const handleMouseDown = (event: React.MouseEvent, data: CanvasElement) => {
    for (const canvas of canvasRef.current.canvases) {
      const ref = canvas.ref.current;

      if (!ref) return;

      const rect = ref.getBoundingClientRect();
      const threshold = ref.width * 0.03;
      const bgImg = canvas.elements.find(isImageElement);

      if (!bgImg) return;

      const scale = bgImg.state.img.naturalWidth / rect.width;
      const clickX = (event.clientX - rect.left) * scale;
      const clickY = (event.clientY - rect.top) * scale;
      if (
        canvas.elements.find(
          (element) =>
            element.containsPoint(clickX, clickY, threshold) &&
            element.properties.active,
        )
      ) {
        return;
      }
    }

    const draggedElement = data.ref.current;

    if (!draggedElement) return;

    setDraggedCanvas(data.tempId);
    setDragOffset(event.clientX - draggedElement.getBoundingClientRect().left); // Set initial mouse offset
    setPositions(
      canvasRef.current.canvases.map(
        (data) => data.ref.current?.getBoundingClientRect().left ?? 0,
      ),
    );
  };

  const handleMouseUp = () => {
    setDraggedCanvas(null);
    setDragOffset(0);
    setTransforms({});
    setTransitions({});
  };

  const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
    if (draggedCanvas === null) return;

    const draggedCanvasIndex = canvasRef.current.canvases.findIndex(
      (data) => data.tempId === draggedCanvas,
    );
    const deltaX = event.clientX - dragOffset - positions[draggedCanvasIndex];
    const draggedElement =
      canvasRef.current.canvases[draggedCanvasIndex].ref.current?.parentElement;
    if (!draggedElement) return;

    setTransforms((prev) => ({
      ...prev,
      [draggedCanvas]: deltaX,
    }));

    const draggedRect = draggedElement.getBoundingClientRect();

    canvasRef.current.canvases.forEach((data, i) => {
      if (data.tempId !== draggedCanvas) {
        const otherCanvas = data.ref.current;
        if (!otherCanvas) return;

        const otherRect = otherCanvas.getBoundingClientRect();

        if (
          ((draggedRect.right > otherRect.left + otherRect.width / 2 + 10 &&
            draggedCanvasIndex < i) ||
            (draggedRect.left < otherRect.right - otherRect.width / 2 - 10 &&
              draggedCanvasIndex > i)) &&
          otherRect.left === positions[i]
        ) {
          canvasRef.current.canvases = [...canvasRef.current.canvases];
          const temp = canvasRef.current.canvases[draggedCanvasIndex];
          canvasRef.current.canvases[draggedCanvasIndex] =
            canvasRef.current.canvases[i];
          canvasRef.current.canvases[i] = temp;
          setTransforms((prevTransforms) => ({
            ...prevTransforms,
            [draggedCanvas]: event.clientX - dragOffset - positions[i],
            [data.tempId]: positions[i] - positions[draggedCanvasIndex],
          }));
          setTransitions((prev) => ({
            ...prev,
            [data.tempId]: false,
          }));
          requestAnimationFrame(() => {
            requestAnimationFrame(() => {
              setTransitions((prev) => ({
                ...prev,
                [data.tempId]: true,
              }));
              setTransforms((prevTransforms) => ({
                ...prevTransforms,
                [data.tempId]: 0,
              }));
            });
          });
          return;
        }
      }
    });
  };

  return (
    <div
      id="canvases"
      role="presentation"
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      className="flex flex-row justify-center items-center grow flex-wrap gap-8 bg-[#111111] rounded-2xl overflow-y-auto p-5"
    >
      {!projectLoading &&
        (!canvasRef.current.canvases.length ? (
          <p className="text-lg text-center text-white">
            No slides yet. Add one by clicking the plus icon in the top.
          </p>
        ) : (
          canvasRef.current.canvases.map((canvasElement, index) => (
            <Canvas
              key={index}
              onMouseDown={(event) => {
                handleMouseDown(event, canvasElement);
              }}
              canvasRef={canvasRef}
              canvasElement={canvasElement}
              ref={canvasElement.ref}
              style={{
                transform: `translateX(${transforms[canvasElement.tempId] || 0}px)`,
                transition: transitions[canvasElement.tempId]
                  ? "transform 0.3s ease"
                  : "none",
                opacity: draggedCanvas === canvasElement.tempId ? 0.7 : 1,
                zIndex: draggedCanvas === canvasElement.tempId ? 1 : 0,
              }}
            />
          ))
        ))}
    </div>
  );
};

export default CanvasArranger;
