import { Element, ElementCreate } from "@tikifu/shared/types";
import { API_URL } from "../util/config";
import { del, post, put } from "../util/req";
import { debounce } from "../util/helpers";

const createElement = (body: ElementCreate) => {
  return post<Element>(`${API_URL}/elements`, body);
};

// eslint-disable-next-line
const updateElement = <T extends object>(id: number, body: T) =>
  put<Element>(`${API_URL}/elements/${id}`, body);

const deleteElement = (id: number) => {
  return del(`${API_URL}/elements/${id}`);
};

const debouncedUpdateElement = debounce(updateElement, 500);

export { createElement, updateElement, deleteElement, debouncedUpdateElement };
