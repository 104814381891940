import React from "react";
import { Palette, Square, TextBolder, TextT } from "@phosphor-icons/react";
import { ElementFunctionNames } from "../../../types";
import { Element } from "../elements/Element";
import { isCanvasElement, isImageElement, isTextElement } from "../util/guards";
import Dropdown from "../../../components/Dropdown";
import ColorPicker from "../../../components/ColorPicker";
import NumberInput from "../../../components/NumberInput";
import Tooltip from "../../../components/Tooltip";
import Toggle from "../../../components/Toggle";
import Button from "../../../components/Button";

interface CanvasRenderPropsMap {
  number: {
    onChange: (value: number) => void;
    defaultValue: number;
    key: number;
  };
  dropdown: {
    onChange: (value: string) => void;
    defaultValue?: string;
    key: number;
  };
  color: {
    onChange: (value: string) => void;
    defaultValue: string;
    key: number;
  };
  button: { onClick: () => void; defaultValue?: string; key: number };
}

type CanvasOptionType = keyof CanvasRenderPropsMap;

interface CanvasOptionsConfigObject<
  T extends CanvasOptionType = CanvasOptionType,
> {
  function: ElementFunctionNames;
  elementTypes: Element["properties"]["type"][];
  args: any[];
  type: T;
  render: (args: CanvasRenderPropsMap[T]) => React.JSX.Element;
  condition?: (element: Element) => boolean;
}

const createCanvasOption = <T extends CanvasOptionType>(
  config: CanvasOptionsConfigObject<T>,
): CanvasOptionsConfigObject<T> => config;

const canvasOptionsConfig = [
  createCanvasOption({
    function: "setProperties",
    elementTypes: ["text"],
    args: ["fontFamily"],
    type: "dropdown",
    render: ({ onChange, defaultValue, key }) => (
      <Dropdown
        key={key}
        tooltipPosition="bottom"
        tooltipText="Font family"
        options={[
          "TikTok Sans",
          "Roboto",
          "Open Sans",
          "Lato",
          "Montserrat",
          "Poppins",
          "Raleway",
          "Oswald",
          "Merriweather",
          "Nunito",
          "Playfair Display",
          "Rubik",
          "Source Sans Pro",
          "PT Sans",
          "Noto Sans",
          "Work Sans",
          "Ubuntu",
          "Fira Sans",
          "Cabin",
          "Quicksand",
          "Josefin Sans",
        ]}
        defaultValue={defaultValue ?? "Font family"}
        onChange={onChange}
      />
    ),
  }),
  createCanvasOption({
    function: "setProperties",
    elementTypes: ["text"],
    args: ["fontSize"],
    type: "number",
    render: ({ onChange, defaultValue, key }) => (
      <NumberInput
        key={key}
        minusTooltip="Decrease font size"
        plusTooltip="Increase font size"
        inputTooltip="Font size"
        max={1000}
        onChange={onChange}
        defaultValue={defaultValue}
        decimals={0}
      />
    ),
  }),
  createCanvasOption({
    function: "setProperties",
    elementTypes: ["text"],
    args: ["fillStyle"],
    type: "color",
    render: ({ onChange, defaultValue, key }) => (
      <Tooltip key={key} position="bottom" text="Text color">
        <ColorPicker
          icon={<TextT size={24} />}
          defaultValue={defaultValue}
          onChange={onChange}
        />
      </Tooltip>
    ),
  }),
  createCanvasOption({
    function: "setProperties",
    elementTypes: ["text"],
    args: ["fontWeight"],
    type: "button",
    render: ({ onClick, defaultValue, key }) => (
      <Tooltip key={key} position="bottom" text="Bold">
        <Toggle defaultValue={defaultValue === "700"} onClick={onClick}>
          <TextBolder size={24} weight="bold" />
        </Toggle>
      </Tooltip>
    ),
  }),
  createCanvasOption({
    function: "setProperties",
    elementTypes: ["text"],
    args: ["lineWidth"],
    type: "number",
    render: ({ onChange, defaultValue, key }) => (
      <NumberInput
        key={key}
        minusTooltip="Decrease border width"
        plusTooltip="Increase border width"
        inputTooltip="Border width"
        min={0}
        max={10}
        step={0.1}
        onChange={onChange}
        defaultValue={defaultValue}
        decimals={1}
      />
    ),
  }),
  createCanvasOption({
    function: "setProperties",
    elementTypes: ["text"],
    args: ["strokeStyle"],
    type: "color",
    render: ({ onChange, defaultValue, key }) => (
      <Tooltip key={key} position="bottom" text="Border color">
        <ColorPicker
          icon={<Square size={24} />}
          defaultValue={defaultValue}
          onChange={onChange}
        />
      </Tooltip>
    ),
  }),
  createCanvasOption({
    function: "setProperties",
    elementTypes: ["text"],
    args: ["bg"],
    type: "dropdown",
    render: ({ onChange, defaultValue, key }) => (
      <Dropdown
        key={key}
        tooltipText="Background type"
        options={["none", "cover", "line"]}
        defaultValue={defaultValue ?? "Background type"}
        onChange={onChange}
      />
    ),
  }),
  createCanvasOption({
    function: "setProperties",
    elementTypes: ["text"],
    args: ["bgColor"],
    type: "color",
    render: ({ onChange, defaultValue, key }) => (
      <Tooltip key={key} position="bottom" text="Background color">
        <ColorPicker
          icon={<Palette size={24} />}
          defaultValue={defaultValue}
          onChange={onChange}
        />
      </Tooltip>
    ),
  }),
  createCanvasOption({
    function: "changeImage",
    args: [],
    elementTypes: ["canvas"],
    type: "button",
    condition: (element) =>
      isCanvasElement(element) &&
      !!element.elements.find(
        (element) =>
          isImageElement(element) && element.properties.sources.length > 1,
      ),
    render: ({ onClick, key }) => (
      <Button key={key} onClick={onClick}>
        Change image
      </Button>
    ),
  }),
  createCanvasOption({
    function: "changeText",
    args: [],
    elementTypes: ["text"],
    type: "button",
    condition: (element) =>
      isTextElement(element) && element.properties.texts.length > 1,
    render: ({ onClick, key }) => (
      <Button key={key} onClick={onClick}>
        Change text
      </Button>
    ),
  }),
];

export default canvasOptionsConfig;
