import WebFont from "webfontloader";
import { TextElementProperties } from "@tikifu/shared/types";
import { debouncedUpdateElement } from "../../../services/elements";
import { MovableElement } from "./MovableElement";

const drawBackground = (
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number,
  padding: number,
  borderRadius: number,
) => {
  const bgX = x - width / 2 - padding;
  const bgY = y - height / 2 - padding;
  const bgWidth = width + padding * 2;
  const bgHeight = height + padding * 2;

  ctx.beginPath();
  ctx.moveTo(bgX + borderRadius, bgY);
  ctx.lineTo(bgX + bgWidth - borderRadius, bgY);
  ctx.quadraticCurveTo(bgX + bgWidth, bgY, bgX + bgWidth, bgY + borderRadius);
  ctx.lineTo(bgX + bgWidth, bgY + bgHeight - borderRadius);
  ctx.quadraticCurveTo(
    bgX + bgWidth,
    bgY + bgHeight,
    bgX + bgWidth - borderRadius,
    bgY + bgHeight,
  );
  ctx.lineTo(bgX + borderRadius, bgY + bgHeight);
  ctx.quadraticCurveTo(bgX, bgY + bgHeight, bgX, bgY + bgHeight - borderRadius);
  ctx.lineTo(bgX, bgY + borderRadius);
  ctx.quadraticCurveTo(bgX, bgY, bgX + borderRadius, bgY);
  ctx.closePath();

  ctx.fill();
};

export class TextElement extends MovableElement {
  properties: TextElementProperties;
  state: MovableElement["state"] & {
    editable: boolean;
    fontSizeChanged: boolean;
  };

  constructor(texts: string[]) {
    super();
    this.properties = {
      type: "text",
      active: true,
      texts: texts.map((text) => text.replace(/\\n/g, "\n")),
      text: texts[0] ?? "new text",
      x: 0,
      y: 0,
      fontSize: 0,
      fontFamily: "TikTok Sans",
      fillStyle: "#ffffff",
      fontWeight: "400",
      strokeStyle: "black",
      lineWidth: 1,
      bg: "none",
      bgColor: "#ffff",
    };
    this.state = {
      x: 0,
      y: 0,
      width: 0,
      height: 0,
      dragging: false,
      selected: false,
      editable: false,
      fontSizeChanged: false,
    };
  }

  async loadFont(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.properties.fontFamily === "TikTok Sans") {
        document.fonts.onloadingdone = () => {
          console.log("font loading done");
          resolve();
        };

        document.fonts.onloadingerror = () => {
          reject(new Error("Loading font failed"));
        };

        void document.fonts.load(
          `1em ${this.properties.fontFamily}`,
          this.properties.fontWeight,
        );
      } else {
        WebFont.load({
          google: {
            families: [`${this.properties.fontFamily}:400,700`],
          },
          active: () => {
            resolve();
          },
          inactive: () => {
            reject(new Error("Google Font loading failed"));
          },
        });
      }
    });
  }

  draw(ctx: CanvasRenderingContext2D): void {
    const canvasWidth = ctx.canvas.width;

    if (!this.state.width || !this.state.height) {
      this.state.width = 0.5 * canvasWidth;
      this.state.height = 0.5 * canvasWidth;
    }

    if (this.properties.x === 0 && this.properties.y === 0) {
      this.state.x = canvasWidth / 2;
      this.state.y = ctx.canvas.height / 2;
      this.properties.x = canvasWidth / 2;
      this.properties.y = ctx.canvas.height / 2;
    }

    ctx.textAlign = "center";
    ctx.textBaseline = "top";
    ctx.direction = "inherit";
    ctx.fillStyle = this.properties.fillStyle;
    ctx.lineWidth = this.properties.lineWidth;
    ctx.strokeStyle = this.properties.strokeStyle;
    ctx.fillStyle = this.properties.bgColor;
    ctx.font = `${this.properties.fontWeight} ${this.properties.fontSize}px ${this.properties.fontFamily}`;
    console.log("fontweight", this.properties.fontWeight);

    const lines = this.properties.text.split("\n");

    let lineHeight = this.properties.fontSize * 1.2;
    let textMetrics = lines.map((line) => ctx.measureText(line));

    let maxTextHeight =
      lines.length * lineHeight -
      (lineHeight -
        (textMetrics[0].actualBoundingBoxAscent +
          textMetrics[0].actualBoundingBoxDescent)) /
        2 -
      (lineHeight -
        (textMetrics[textMetrics.length - 1].actualBoundingBoxAscent +
          textMetrics[textMetrics.length - 1].actualBoundingBoxDescent)) /
        2;

    let maxTextWidth = Math.max(...textMetrics.map((metrics) => metrics.width));

    if (this.state.fontSizeChanged) {
      if (
        maxTextHeight <= this.state.height &&
        maxTextWidth <= this.state.width
      ) {
        if (
          Math.abs(maxTextHeight - this.state.height) <
          Math.abs(maxTextWidth - this.state.width)
        ) {
          this.state.height = maxTextHeight;
        } else {
          this.state.width = maxTextWidth;
        }
      } else {
        if (this.state.height < maxTextHeight) {
          this.state.height = maxTextHeight;
        }
        if (this.state.width < maxTextWidth) {
          this.state.width = maxTextWidth;
        }
      }
    } else {
      let minFontSize = 1;
      let maxFontSize = Math.max(this.state.width, this.state.height);
      let fontSize = minFontSize;

      while (minFontSize <= maxFontSize) {
        const midFontSize = Math.floor((minFontSize + maxFontSize) / 2);
        ctx.font = `${this.properties.fontWeight} ${midFontSize}px ${this.properties.fontFamily}`;

        textMetrics = lines.map((line) => ctx.measureText(line));
        lineHeight = midFontSize * 1.2;
        maxTextWidth = Math.max(...textMetrics.map((metrics) => metrics.width));
        maxTextHeight =
          lines.length * lineHeight -
          (lineHeight -
            (textMetrics[0].actualBoundingBoxAscent +
              textMetrics[0].actualBoundingBoxDescent)) /
            2 -
          (lineHeight -
            (textMetrics[textMetrics.length - 1].actualBoundingBoxAscent +
              textMetrics[textMetrics.length - 1].actualBoundingBoxDescent)) /
            2;

        if (
          maxTextWidth <= this.state.width &&
          maxTextHeight <= this.state.height
        ) {
          fontSize = midFontSize;
          minFontSize = midFontSize + 1;
        } else {
          maxFontSize = midFontSize - 1;
        }
      }

      this.properties.fontSize = fontSize;
    }

    if (this.properties.bg === "cover") {
      drawBackground(
        ctx,
        this.properties.x,
        this.properties.y,
        maxTextWidth,
        maxTextHeight,
        0.3 * this.properties.fontSize,
        0.02 * canvasWidth,
      );
    }

    if (this.properties.bg === "line") {
      lines.forEach((_, index) => {
        const y =
          this.properties.y -
          ((lines.length - 1) * lineHeight) / 2 +
          index * lineHeight;

        const textHeight =
          textMetrics[index].actualBoundingBoxAscent +
          textMetrics[index].actualBoundingBoxDescent;

        drawBackground(
          ctx,
          this.properties.x,
          y,
          textMetrics[index].width,
          textHeight,
          0.1 * this.properties.fontSize,
          0.02 * canvasWidth,
        );
      });
    }

    ctx.fillStyle = this.properties.fillStyle;

    lines.forEach((line, index) => {
      const offset =
        (textMetrics[index].actualBoundingBoxAscent -
          textMetrics[index].actualBoundingBoxDescent) /
        2;

      const y =
        this.properties.y -
        ((lines.length - 1) * lineHeight) / 2 +
        index * lineHeight +
        offset;

      ctx.save();
      ctx.lineWidth = this.properties.lineWidth * 2;
      ctx.strokeText(line, this.properties.x, y);
      ctx.restore();

      ctx.fillText(line, this.properties.x, y);
    });
  }

  changeText(): void {
    this.properties.fontSize = 0;
    this.properties.text =
      this.properties.texts[
        Math.floor(Math.random() * this.properties.texts.length)
      ];
  }

  // eslint-disable-next-line
  async setProperties(properties: Partial<this["properties"]>) {
    const updatedProperties = properties;

    if ("fontSize" in properties) {
      this.state.fontSizeChanged = true;
    } else {
      this.state.fontSizeChanged = false;
    }

    if ("fontWeight" in properties) {
      if (this.properties.fontWeight === "400") {
        this.properties.fontWeight = "700";
      } else {
        this.properties.fontWeight = "400";
      }
      updatedProperties.fontWeight = this.properties.fontWeight;
    }

    this.properties = { ...this.properties, ...updatedProperties };

    if (this.id) {
      debouncedUpdateElement(this.id, {
        properties: updatedProperties,
      });
    }

    if ("fontFamily" in properties) {
      console.log("setProperties");
      return this.loadFont();
    }
  }
}
