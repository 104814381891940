import { useParams } from "react-router-dom";
import { useRef } from "react";
import { useAppStore } from "../../hooks/useAppStore";
import { scheduleTiktokPool } from "../../services/schedule";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import SelectableBlocks from "../../components/SelectableBlocks";

const Schedule = () => {
  const { selectedUser, modal, setModal } = useAppStore();
  const { id: projectId } = useParams();
  const poolStartHours = useRef<number[]>([]);

  const handleScheduleSlideshows = () => {
    if (!poolStartHours.current.length || !projectId || !selectedUser) return;

    void scheduleTiktokPool(selectedUser, {
      projectId: Number(projectId),
      startHours: poolStartHours.current,
      combinationIndex: 0,
    });
  };

  return (
    <Modal
      open={modal === "schedule"}
      onClose={() => {
        setModal(null);
      }}
    >
      <h2 className="text-xl font-semibold">Schedule slideshow</h2>
      <p>
        Choose during which three hour time windows your slideshows should be
        uploaded as drafts each day. Each slideshow uploaed will be an unique
        combination made from the slides and texts provided.
      </p>
      <div className="flex gap-2 items-center">
        <span>00:00</span>
        <SelectableBlocks
          deselect
          selectMultiple
          options={Array.from({ length: 8 }, (_, i) => i * 3).map((value) => ({
            value,
            item: <div />,
          }))}
          onChange={(startHours) => {
            poolStartHours.current = startHours;
          }}
          blockClassName="px-[14px] py-[14px]"
        />
        <span>00:00</span>
      </div>
      <Button
        className="w-full"
        access="pro"
        provider="tiktok"
        color="white"
        onClick={handleScheduleSlideshows}
      >
        Schedule
      </Button>
    </Modal>
  );
};

export default Schedule;
