import { useState } from "react";
import { RunPodJob } from "@tikifu/shared/types";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import { useAppStore } from "../../hooks/useAppStore";
import { checkGenerationStatus, generateImage } from "../../services/generate";

interface GenerateProps {
  onFinish: (imageUrls: string[]) => void;
}

const Generate = ({ onFinish }: GenerateProps) => {
  const { modal, setModal } = useAppStore();
  const [status, setStatus] = useState<RunPodJob["status"] | "">("");
  const [prompt, setPrompt] = useState("");

  const handleGenerate = async () => {
    try {
      const newJob = await generateImage({ prompt });
      setStatus(newJob.status);

      const intervalId = setInterval(() => {
        void (async () => {
          try {
            const job = await checkGenerationStatus(newJob.id);
            if (job.status === "COMPLETED") {
              onFinish(job.urls);
              clearInterval(intervalId);
            }
            setStatus(job.status);
          } catch (e) {
            console.error("Error fetching generation status", e);
          }
        })();
      }, 2000);
    } catch (e) {
      console.error("Error starting image generation", e);
    }
  };

  const loading = status === "IN_PROGRESS" || status === "IN_QUEUE";

  return (
    <Modal
      open={modal === "generate"}
      onClose={() => {
        setModal(null);
      }}
    >
      <h2 className="text-xl font-semibold">Generate slide</h2>
      <p>
        Write a prompt to use for generating one or more slides. Generating
        multiple slides can be used to create multiple slideshows easily.
      </p>
      <div className="flex flex-col gap-3 w-full">
        <textarea
          className="bg-transparent text-white border border-white py-1 px-2 rounded cursor-pointer text-base"
          value={prompt}
          onChange={(e) => {
            setPrompt(e.target.value);
          }}
        ></textarea>
        <Button
          className={loading ? "animate-pulse" : ""}
          onClick={() => void handleGenerate()}
          color="white"
        >
          {loading ? "Generating..." : "Generate"}
        </Button>
      </div>
    </Modal>
  );
};

export default Generate;
