import Editor from "../../features/Editor";

const Project = () => {
  return (
    <>
      <Editor />
    </>
  );
};

export default Project;
