import { create } from "zustand";

interface EditorState {
  canvasOptionsRenderCount: number;
  canvasArrangerRenderCount: number;
  projectLoading: boolean;
  renderCanvasOptions: () => void;
  renderCanvasArranger: () => void;
  setProjectLoading: (loading: boolean | ((prev: boolean) => boolean)) => void;
}

const useEditorStore = create<EditorState>((set) => ({
  canvasArrangerRenderCount: 0,
  canvasOptionsRenderCount: 0,
  projectLoading: true,
  renderCanvasOptions: () => {
    set((state) => ({
      canvasOptionsRenderCount: state.canvasOptionsRenderCount + 1,
    }));
  },
  renderCanvasArranger: () => {
    set((state) => ({
      canvasArrangerRenderCount: state.canvasArrangerRenderCount + 1,
    }));
  },
  setProjectLoading: (projectLoading) => {
    set((state) => ({
      projectLoading:
        typeof projectLoading === "function"
          ? projectLoading(state.projectLoading)
          : projectLoading,
    }));
  },
}));

export default useEditorStore;
