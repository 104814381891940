import { createRef, useMemo, useRef } from "react";
import {
  ImageElementProperties,
  TextElementProperties,
} from "@tikifu/shared/types";
import { useParams } from "react-router-dom";
import { Export, Plus, Trash } from "@phosphor-icons/react";
import { TextElement } from "../elements/TextElement";
import { CanvasElement } from "../elements/CanvasElement";
import Button from "../../../components/Button";
import Dropdown from "../../../components/Dropdown";
import { urlsToJpeg } from "../util/helpers";
import { createElement, deleteElement } from "../../../services/elements";
import { ImageElement } from "../elements/ImageElement";
import { createCanvas, deleteCanvas } from "../../../services/canvases";
import { createImages } from "../../../services/images";
import useEditorStore from "../hooks/useEditorStore";
import canvasOptionsConfig from "../configs/canvasOptionsConfig";
import { CanvasRef, ElementFunctionNames } from "../../../types";
import { useAppStore } from "../../../hooks/useAppStore";
import Download from "../../Download";
import Upload from "../../Upload";
import Schedule from "../../Schedule";
import Modal from "../../../components/Modal";
import Tooltip from "../../../components/Tooltip";
import Generate from "../../Generate";
import { MovableElement } from "../elements/MovableElement";

interface CanvasOptionsProps {
  canvasRef: CanvasRef;
}

const CanvasOptions = ({ canvasRef }: CanvasOptionsProps) => {
  const { modal, setModal } = useAppStore();
  // @ts-expect-error This state is used only for rendering
  // eslint-disable-next-line
  const { canvasOptionsRenderCount, renderCanvasArranger } = useEditorStore();
  const { id: projectId } = useParams();
  const folderFileInputRef = useRef<HTMLInputElement>(null);
  const singleFileInputRef = useRef<HTMLInputElement>(null);
  const textFileInputRef = useRef<HTMLInputElement>(null);

  const selectedElements = canvasRef.current.canvases
    .map((canvas) => canvas.elements)
    .flat()
    .filter((element) => element.state.selected);

  const selectedElementTypes = [
    ...new Set(selectedElements.map((element) => element.properties.type)),
  ];

  const showCanvasOptions = canvasRef.current.canvases.some(
    (canvas) => canvas.state.selected,
  );

  const handlers = useMemo(
    () => ({
      handleElementFunctionCall: async (
        functionName: ElementFunctionNames,
        args: any[],
      ) => {
        try {
          await canvasRef.current.callSelected(functionName, args);
        } catch (e) {
          console.error(
            `Error calling ${functionName} with args ${args.toString()} for selected elements`,
            e,
          );
        }
      },
      handleFileSelect: async (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = Array.from(event.target.files ?? []);
        const imageUrls = files.map((file) => URL.createObjectURL(file));
        try {
          const jpegBlobs: Blob[] = await urlsToJpeg(imageUrls);
          const { urls } = await createImages({ images: jpegBlobs });
          await handlers.handleAddImages(urls);
        } catch (e) {
          console.error("Error uploading images", e);
        }
        event.target.value = "";
      },
      handleAddImages: async (imageUrls: string[]) => {
        const bgImg = new ImageElement(
          imageUrls,
          { x: 0, y: 0 },
          "auto",
          "auto",
        );

        const elements = [bgImg];
        const newCanvasElement = new CanvasElement(createRef());
        newCanvasElement.elements = elements;
        canvasRef.current.canvases.push(newCanvasElement);

        try {
          await elements[0].load();
          renderCanvasArranger();
          setModal(null);

          if (!projectId) return;

          const { id } = await createCanvas({ projectId: Number(projectId) });
          newCanvasElement.id = id;
          await createElement({
            canvasId: id,
            properties: { ...bgImg.properties, sources: imageUrls },
          });
        } catch (e) {
          console.error("Error creating canvas", e);
          canvasRef.current.canvases.pop();
          renderCanvasArranger();
        }
      },
      handleAddText: async (texts: string[]) => {
        for (const canvas of canvasRef.current.canvases) {
          if (canvas.state.selected) {
            const newTextObj = new TextElement([...texts]);

            canvas.elements.push(newTextObj);

            try {
              await newTextObj.loadFont();
              console.log("addtext");
              canvas.draw();

              setModal(null);

              if (!canvas.id) return;

              const obj = await createElement({
                canvasId: canvas.id,
                properties: newTextObj.properties,
              });
              newTextObj.id = obj.id;
            } catch (e: unknown) {
              console.error("Error creating element", e);
              canvas.elements.pop();
              canvas.draw();
            }
          }
        }
      },
      handleAddTextFromFile: (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) {
          return;
        }
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
          const text = e.target?.result;
          if (text && typeof text === "string") {
            void handlers.handleAddText(text.split(/\r?\n/));
          }
        };
        reader.readAsText(file);
      },
      handleRemoveCanvas: async () => {
        const oldCanvases = canvasRef.current.canvases;
        const deleteCanvasPromises = [];
        for (const canvas of canvasRef.current.canvases) {
          if (canvas.state.selected && canvas.id) {
            deleteCanvasPromises.push(deleteCanvas(canvas.id));
          }
        }
        canvasRef.current.canvases = canvasRef.current.canvases.filter(
          (canvas) => !canvas.state.selected || !canvas.id,
        );
        renderCanvasArranger();
        try {
          await Promise.all(deleteCanvasPromises);
        } catch (e) {
          console.error("Error deleting canvases", e);
          canvasRef.current.canvases = oldCanvases;
          renderCanvasArranger();
        }
      },
      handleRemoveElement: async () => {
        const oldElements = new Map<CanvasElement, MovableElement[]>();
        const deleteElementPromises = [];
        for (const canvas of canvasRef.current.canvases) {
          for (const element of canvas.elements) {
            if (element.state.selected && element.id) {
              deleteElementPromises.push(deleteElement(element.id));
            }
          }
          oldElements.set(canvas, canvas.elements);
          canvas.elements = canvas.elements.filter(
            (element) => !element.state.selected || !element.id,
          );
        }
        canvasRef.current.draw();
        try {
          await Promise.all(deleteElementPromises);
        } catch (e) {
          console.error("Error deleting elements", e);
          for (const [canvas, elements] of oldElements) {
            canvas.elements = elements;
          }
          canvasRef.current.draw();
        }
      },
    }),
    [],
  );

  const getCommonValue = (
    property: keyof TextElementProperties | keyof ImageElementProperties,
  ) => {
    if (selectedElements.length === 0) return "";
    const uniqueProperties = new Set<string | string[] | number | boolean>();
    selectedElements.forEach((element) => {
      if (property in element.properties) {
        uniqueProperties.add(
          element.properties[property as keyof typeof element.properties],
        );
      }
    });
    const commonValue = [...uniqueProperties][0];
    return uniqueProperties.size === 1 && typeof commonValue !== "boolean"
      ? commonValue
      : "";
  };

  return (
    <>
      <div
        id="top-bar"
        className="sticky top-0 left-0 w-full bg-[#1B1B1B] shadow-md gray-800 text-white flex flex-wrap items-center justify-between gap-4 p-3.5 z-[1000]"
      >
        <Dropdown
          defaultValue=""
          options={[]}
          onChange={() => null}
          className="invisible"
          icon={
            <Button
              onClick={() => null}
              color="white"
              className="flex flex-row gap-2 items-center"
            >
              <span>Export</span>
              <Export size={24} />
            </Button>
          }
        ></Dropdown>
        <div className="flex flex-row justify-center gap-4">
          {selectedElementTypes.length > 0 ? (
            <>
              {canvasOptionsConfig
                .filter((config) =>
                  selectedElementTypes.every((elementType) =>
                    config.elementTypes.includes(elementType),
                  ),
                )
                .map((config, index) => {
                  const commonValue = getCommonValue(
                    config.args[0] as
                      | keyof TextElementProperties
                      | keyof ImageElementProperties,
                  );

                  if (
                    config.condition &&
                    !selectedElements.every(config.condition)
                  ) {
                    return null;
                  }

                  const createArgs = (value: string | number) =>
                    config.args.length
                      ? { [config.args[0]]: value }
                      : undefined;

                  if (config.type === "dropdown") {
                    return config.render({
                      onChange: (value) => {
                        void handlers.handleElementFunctionCall(
                          config.function,
                          [createArgs(value)],
                        );
                      },
                      key: index,
                      defaultValue:
                        typeof commonValue === "string"
                          ? commonValue
                          : undefined,
                    });
                  } else if (config.type === "color") {
                    return config.render({
                      onChange: (value) => {
                        void handlers.handleElementFunctionCall(
                          config.function,
                          [createArgs(value)],
                        );
                      },
                      key: index,
                      defaultValue:
                        typeof commonValue === "string"
                          ? commonValue
                          : "#000000",
                    });
                  } else if (config.type === "number") {
                    return config.render({
                      onChange: (value) => {
                        void handlers.handleElementFunctionCall(
                          config.function,
                          [createArgs(value)],
                        );
                      },
                      key: index,
                      defaultValue:
                        typeof commonValue === "number" ? commonValue : 0,
                    });
                  } else {
                    return config.render({
                      onClick: () => {
                        void handlers.handleElementFunctionCall(
                          config.function,
                          [createArgs("400")],
                        );
                      },
                      key: index,
                      defaultValue:
                        typeof commonValue === "string" ? commonValue : "",
                    });
                  }
                })}
              <Tooltip position="bottom" text="Delete">
                <Button
                  onClick={() => void handlers.handleRemoveElement()}
                  className={`!py-0 !px-0 w-[34px] h-[34px] border-none`}
                >
                  <Trash size={24} />
                </Button>
              </Tooltip>
            </>
          ) : showCanvasOptions ? (
            <>
              <Tooltip position="bottom" text="Add Text">
                <Button
                  onClick={() => {
                    setModal("addText");
                  }}
                  className={`!py-0 !px-0 w-[34px] h-[34px] border-none`}
                >
                  <Plus size={24} />
                </Button>
              </Tooltip>
              <Tooltip position="bottom" text="Delete">
                <Button
                  onClick={() => void handlers.handleRemoveCanvas()}
                  className={`!py-0 !px-0 w-[34px] h-[34px] border-none`}
                >
                  <Trash size={24} />
                </Button>
              </Tooltip>
            </>
          ) : (
            <Tooltip position="bottom" text="Add Slide">
              <Button
                onClick={() => {
                  setModal("addSlide");
                }}
                className={`!py-0 !px-0 w-[34px] h-[34px] border-none`}
              >
                <Plus size={24} />
              </Button>
            </Tooltip>
          )}
        </div>
        <Dropdown
          options={["Download", "Schedule", "Upload"]}
          onChange={(option) => {
            setModal(
              option.toLowerCase() as "download" | "schedule" | "upload",
            );
          }}
          icon={
            <Button color="white" className="flex flex-row gap-2 items-center">
              <span>Export</span>
              <Export size={24} />
            </Button>
          }
        ></Dropdown>
      </div>
      <input
        ref={folderFileInputRef}
        type="file"
        accept="image/*"
        className="hidden"
        multiple
        // @ts-expect-error Webkitdirectory is not recognized by TypeScript
        webkitdirectory="true"
        onChange={(e) => {
          void handlers.handleFileSelect(e);
        }}
      />
      <input
        ref={singleFileInputRef}
        type="file"
        accept="image/*"
        className="hidden"
        onChange={(e) => {
          void handlers.handleFileSelect(e);
        }}
      />
      <input
        className="hidden"
        ref={textFileInputRef}
        type="file"
        onChange={handlers.handleAddTextFromFile}
      />
      <Download canvasRef={canvasRef} />
      <Upload />
      <Schedule />
      <Modal
        open={modal === "addSlide"}
        onClose={() => {
          setModal(null);
        }}
      >
        <h2 className="text-xl font-semibold">Add slide</h2>
        <p>
          Add a slide either from a single image or a folder of images, which
          can be used to create multiple slideshows easily. You can also
          generate a new slide with AI.
        </p>
        <div className="flex flex-col gap-3 w-full">
          <Button
            onClick={() => {
              singleFileInputRef.current?.click();
            }}
            color="white"
          >
            Single image
          </Button>
          <Button
            onClick={() => {
              folderFileInputRef.current?.click();
            }}
            color="white"
          >
            Folder
          </Button>
          <Button
            onClick={() => {
              setModal("generate");
            }}
            color="white"
          >
            Generate
          </Button>
        </div>
      </Modal>
      <Modal
        open={modal === "addText"}
        onClose={() => {
          setModal(null);
        }}
      >
        <h2 className="text-xl font-semibold">Add text</h2>
        <p>
          Add a single text or a text file with each text on its own line. Texts
          from files can be used to create multiple slideshows easily.
        </p>
        <div className="flex flex-col gap-3 w-full">
          <Button
            onClick={() => void handlers.handleAddText(["new text"])}
            color="white"
          >
            Single text
          </Button>
          <Button
            onClick={() => {
              textFileInputRef.current?.click();
            }}
            color="white"
          >
            File
          </Button>
        </div>
      </Modal>
      <Generate
        onFinish={(imageUrls) => {
          void handlers.handleAddImages(imageUrls);
        }}
      />
    </>
  );
};

export default CanvasOptions;
