import React, { useState, ReactNode, useRef } from "react";

interface ColorPickerProps {
  onChange: (color: string) => void;
  defaultValue: string;
  icon: ReactNode; // Icon as a ReactNode prop
}

const ColorPicker = ({ onChange, defaultValue, icon }: ColorPickerProps) => {
  const [selectedColor, setSelectedColor] = useState(defaultValue);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedColor(event.target.value);
    onChange(event.target.value);
  };

  return (
    <div
      role="button"
      tabIndex={1}
      onClick={() => {
        if (!inputRef.current) return;

        inputRef.current.click();
      }}
      className="flex flex-col items-center justify-center cursor-pointer rounded hover:bg-white hover:text-gray-900 w-[34px] h-[34px]"
    >
      {icon}
      <div className="relative">
        <input
          ref={inputRef}
          type="color"
          value={selectedColor}
          onChange={handleColorChange}
          className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
        />
        <div
          className="rounded cursor-pointer h-1.5 w-6 border-[0.5px] border-gray-900"
          style={{ backgroundColor: selectedColor }}
        ></div>
      </div>
    </div>
  );
};

export default ColorPicker;
