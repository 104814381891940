import { Element as SharedElement } from "@tikifu/shared/types";

export abstract class Element
  implements Omit<SharedElement, "id" | "canvasId" | "properties">
{
  id?: number;
  tempId = Symbol();
  abstract properties: {
    type: "text" | "image" | "canvas";
  };
  abstract state: {
    width: number;
    height: number;
    selected: boolean;
  };

  abstract draw(ctx: CanvasRenderingContext2D): void;
}
