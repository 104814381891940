import { Project } from "@tikifu/shared/types";
import { createRef } from "react";
import { ImageElement } from "../elements/ImageElement";
import { TextElement } from "../elements/TextElement";
import { cartesianProduct, deterministicShuffle } from "../../../util/helpers";
import { MovableElement } from "../elements/MovableElement";
import { CanvasElement } from "../elements/CanvasElement";
import { CanvasRef } from "../../../types";
import { isImageElement, isTextElement } from "./guards";

export const loadProject = async (
  canvasRef: CanvasRef,
  project: Project,
  plain = false,
) => {
  const loadFontPromises: Promise<void>[] = [];
  const loadImagePromises: Promise<void>[] = [];

  for (const canvas of project.canvases) {
    let newRef;
    if (plain) {
      const canvas = document.createElement("canvas");
      newRef = { current: canvas };
    } else {
      newRef = createRef<HTMLCanvasElement>();
    }

    const elements: MovableElement[] = [];

    canvas.elements.forEach((element) => {
      const properties = element.properties;
      if (properties.type === "image") {
        const bgImg = new ImageElement(
          properties.sources,
          { x: 0, y: 0 },
          "auto",
          "auto",
        );
        loadImagePromises.push(bgImg.load());
        elements.push(bgImg);
      }
      if (element.properties.type === "text") {
        const textObj = new TextElement(element.properties.texts);
        textObj.properties = element.properties;
        textObj.id = element.id;
        loadFontPromises.push(textObj.loadFont());
        elements.push(textObj);
      }
    });

    const newCanvasElement = new CanvasElement(newRef);
    newCanvasElement.id = canvas.id;
    newCanvasElement.elements = elements;
    canvasRef.current.canvases.push(newCanvasElement);
  }

  await Promise.all([...loadFontPromises, ...loadImagePromises]);
};

export const generateSlideshows = (
  canvasRef: CanvasRef,
  numSlideshows?: number,
) => {
  const allCombinations: { imageIndex: number; textIndexes: number[] }[][] = [];

  canvasRef.current.canvases.forEach((slide) => {
    const bgImg = slide.elements.find(isImageElement);

    if (!bgImg) {
      throw new Error("Background image missing from slide");
    }

    const images = bgImg.properties.sources;
    const textSets = slide.elements
      .filter(isTextElement)
      .map((obj) => obj.properties.texts);

    const textIndexSets = textSets.map((texts) =>
      texts.map((_, index) => index),
    );

    const textCombinations = cartesianProduct(...textIndexSets);

    const slideCombinations: { imageIndex: number; textIndexes: number[] }[] =
      [];
    images.forEach((_, imageIndex) => {
      textCombinations.forEach((textIndexes) => {
        slideCombinations.push({ imageIndex, textIndexes });
      });
    });

    allCombinations.push(slideCombinations);
  });

  const allSlideshows = cartesianProduct(...allCombinations);

  deterministicShuffle(allSlideshows, "deternimistic-seed");
  const limitedSlideshows = allSlideshows.slice(0, numSlideshows);

  const resolvedSlideshows = limitedSlideshows.map((slideshow) =>
    slideshow.map(({ imageIndex, textIndexes }, slideIndex) => {
      const slide = canvasRef.current.canvases[slideIndex];
      const bgImg = slide.elements.find(isImageElement);
      if (!bgImg) {
        throw new Error("Background image missing from slide");
      }
      const image = bgImg.properties.sources[imageIndex];
      const texts = textIndexes.map(
        (textIndex, objectIndex) =>
          slide.elements.filter(isTextElement)[objectIndex].properties.texts[
            textIndex
          ],
      );
      return { image, texts };
    }),
  );

  return resolvedSlideshows;
};

export const urlsToJpeg = (dataUrls: string[]) => {
  return Promise.all(
    dataUrls.map(async (url) => {
      const img = new Image();
      img.src = url;

      await new Promise((resolve, reject) => {
        img.onload = resolve;

        img.onerror = (e) => {
          console.error("Error loading image:", e);
          reject(new Error("Image failed to load"));
        };
      });

      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      if (!ctx) {
        throw new Error("Unable to get canvas context");
      }

      canvas.width = img.width;
      canvas.height = img.height;

      ctx.drawImage(img, 0, 0);

      return new Promise<Blob>((resolve, reject) => {
        canvas.toBlob((blob) => {
          if (blob) {
            resolve(blob);
          } else {
            reject(new Error("Canvas toBlob conversion failed"));
          }
        }, "image/jpeg");
      });
    }),
  );
};
