import { X } from "@phosphor-icons/react";
import { ReactNode } from "react";
import { createPortal } from "react-dom";

interface ModalProps {
  children: ReactNode;
  open: boolean;
  onClose: () => void;
}

const Modal = ({ children, open, onClose }: ModalProps) => {
  const root = document.getElementById("root");

  if (!open || !root) return null;

  return createPortal(
    <div className="fixed inset-0 z-[1001]">
      <div
        className="fixed inset-0 bg-black bg-opacity-50 z-[1001]"
        onClick={onClose}
        role="presentation"
      ></div>
      <div className="flex flex-col gap-5 items-center justify-center text-center relative z-[1002] bg-[#111111] p-5 rounded-lg w-full max-w-md shadow-ld top-1/2 -translate-y-1/2 m-auto text-white">
        <button
          className="absolute flex items-center justify-center top-2.5 right-2.5 text-2xl font-bold cursor-pointer hover:bg-[#222222] p-1.5 leading-none rounded-full"
          onClick={onClose}
        >
          <X weight="bold" className="w-5 h-5" />
        </button>
        {children}
      </div>
    </div>,
    root,
  );
};

export default Modal;
