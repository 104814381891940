import { ReactNode, useState } from "react";
import Button from "./Button";

interface Option<T extends string | number> {
  item: ReactNode;
  value: T;
}

interface SelectableBlocksProps<T extends string | number> {
  options: Option<T>[];
  onChange: (value: T[]) => void;
  selectMultiple?: boolean;
  deselect?: boolean;
  blockClassName?: string;
}

const SelectableBlocks = <T extends string | number>({
  options,
  onChange,
  deselect = false,
  selectMultiple = false,
  blockClassName = "",
}: SelectableBlocksProps<T>) => {
  const [selectedBlocks, setSelectedBlocks] = useState<number[]>([]);

  return (
    <div className="flex flex-wrap gap-2 items-center">
      {options.map((option, index) => (
        <Button
          key={option.value}
          className={`gap-2
            ${selectedBlocks.includes(index) ? "bg-white !text-gray-900" : ""} ${blockClassName}
          `}
          onClick={() => {
            setSelectedBlocks((prev) => {
              let newBlocks = [...prev];
              if (prev.includes(index)) {
                if (deselect) {
                  newBlocks = prev.filter((block) => block !== index);
                }
              } else {
                if (!selectMultiple) {
                  newBlocks = [index];
                } else {
                  newBlocks = [...prev, index];
                }
              }
              const selectedValues = newBlocks.map(
                (block) => options[block].value,
              );
              onChange(selectedValues);
              return newBlocks;
            });
          }}
        >
          {option.item}
        </Button>
      ))}
    </div>
  );
};

export default SelectableBlocks;
