import { ImageElementProperties } from "@tikifu/shared/types";
import { MovableElement } from "./MovableElement";

export class ImageElement extends MovableElement {
  state: MovableElement["state"] & {
    img: HTMLImageElement;
  };
  properties: ImageElementProperties;

  constructor(
    sources: string[],
    position: { x: number; y: number },
    width: number | "auto" | "fill",
    height: number | "auto" | "fill",
  ) {
    super();
    this.properties = {
      type: "image",
      active: false,
      x: position.x,
      y: position.y,
      width,
      height,
      currentSource: 0,
      sources,
    };
    this.state = {
      x: position.x,
      y: position.y,
      img: new Image(),
      width: 0,
      height: 0,
      dragging: false,
      selected: false,
    };
    this.state.img.crossOrigin = "anonymous";
  }

  load(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.state.img.onload = () => {
        resolve();
      };
      this.state.img.onerror = () => {
        reject(new Error("Loading image failed"));
      };
      this.state.img.src =
        this.properties.sources[this.properties.currentSource];
    });
  }

  draw(ctx: CanvasRenderingContext2D): void {
    this.state.width =
      this.properties.width === "auto"
        ? this.state.img.naturalWidth
        : this.properties.width === "fill"
          ? ctx.canvas.width
          : this.properties.width;
    this.state.height =
      this.properties.height === "auto"
        ? this.state.img.naturalHeight
        : this.properties.height === "fill"
          ? ctx.canvas.height
          : this.properties.height;

    this.properties.y = ctx.canvas.height / 2;
    this.properties.x = ctx.canvas.width / 2;

    ctx.drawImage(
      this.state.img,
      this.properties.x - this.state.width / 2,
      this.properties.y - this.state.height / 2,
      this.state.width,
      this.state.height,
    );
  }

  changeImage(): Promise<void> {
    this.properties.currentSource =
      this.properties.currentSource < this.properties.sources.length - 1
        ? this.properties.currentSource + 1
        : 0;
    return this.load();
  }
}
