import { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { getProject } from "../../services/projects";
import CanvasArranger from "./components/CanvasArranger";
import { loadProject } from "./util/helpers";
import CanvasOptions from "./components/CanvasOptions";
import { CanvasEditor } from "./elements/CanvasEditor";
import useEditorStore from "./hooks/useEditorStore";

const Editor = () => {
  const { renderCanvasArranger, setProjectLoading } = useEditorStore();
  const canvasRef = useRef<CanvasEditor>(new CanvasEditor());
  const { id } = useParams();

  useEffect(() => {
    const initialize = async () => {
      if (!id) return;
      canvasRef.current.reset();
      try {
        const project = await getProject(Number(id));
        await loadProject(canvasRef, project);
        setProjectLoading(false);
      } catch (e) {
        console.error("Error getting or loading the project", e);
      }
      renderCanvasArranger();
    };

    void initialize();
  }, [id]);

  return (
    <>
      <CanvasOptions canvasRef={canvasRef} />
      <CanvasArranger canvasRef={canvasRef} />
    </>
  );
};

export default Editor;
