import {
  ImageElementProperties,
  TextElementProperties,
} from "@tikifu/shared/types";

export const createFormData = (
  data: Record<
    string,
    | string
    | Blob
    | File
    | number
    | boolean
    | string[]
    | Blob[]
    | TextElementProperties
    | ImageElementProperties
  >,
): FormData => {
  const formData = new FormData();

  Object.entries(data).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item) => {
        formData.append(key, item);
      });
    } else if (typeof value === "boolean" || typeof value === "number") {
      formData.append(key, value.toString());
    } else if (typeof value === "object") {
      formData.append(key, JSON.stringify(value));
    } else {
      formData.append(key, value);
    }
  });

  return formData;
};

export const debounce = <T extends (...args: Parameters<T>) => void>(
  func: T,
  delay: number,
): ((...args: Parameters<T>) => void) => {
  let timer: ReturnType<typeof setTimeout>;

  return (...args: Parameters<T>) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

export const cartesianProduct = <T>(...arrays: T[][]): T[][] => {
  return arrays.reduce<T[][]>(
    (acc, array) => {
      const result: T[][] = [];
      acc.forEach((accItem) => {
        array.forEach((arrayItem) => {
          result.push([...accItem, arrayItem]);
        });
      });
      return result;
    },
    [[]],
  );
};

export const deterministicShuffle = <T>(array: T[], seed: string): T[] => {
  function hash(str: string): number {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = (hash << 5) - hash + str.charCodeAt(i);
      hash |= 0;
    }
    return hash;
  }

  const seedHash = hash(seed);

  return array.slice().sort((a, b) => {
    const hashA = hash(JSON.stringify(a) + String(seedHash));
    const hashB = hash(JSON.stringify(b) + String(seedHash));
    return hashA - hashB;
  });
};
