import React, { MutableRefObject } from "react";
import { CanvasElementProperties } from "@tikifu/shared/types";
import { isImageElement } from "../util/guards";
import { Element } from "./Element";
import { MovableElement } from "./MovableElement";

export class CanvasElement extends Element {
  properties: CanvasElementProperties;
  state: Element["state"];
  elements: MovableElement[];
  ref: MutableRefObject<HTMLCanvasElement | null>;
  lastClickedElement: symbol | null;

  constructor(canvas: React.MutableRefObject<HTMLCanvasElement | null>) {
    super();

    this.ref = canvas;

    this.lastClickedElement = null;

    this.state = {
      width: 0,
      height: 0,
      selected: false,
    };

    this.properties = {
      type: "canvas",
    };

    this.elements = [];
  }

  draw() {
    const canvas = this.ref.current;

    if (!canvas) return;

    const ctx = canvas.getContext("2d");

    if (!ctx) return;

    const bgImg = this.elements.find(isImageElement);

    if (bgImg) {
      ctx.canvas.width = bgImg.state.img.naturalWidth;
      ctx.canvas.height = (16 / 9) * bgImg.state.img.naturalWidth;
      this.state.width = ctx.canvas.width;
      this.state.height = ctx.canvas.height;
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = "black";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
    }

    this.elements.forEach((element) => {
      element.draw(ctx);

      if (element.state.selected) {
        ctx.strokeStyle = "#39FF14";
        ctx.lineWidth = 0.007 * canvas.width;
        ctx.strokeRect(
          element.properties.x - element.state.width / 2,
          element.properties.y - element.state.height / 2,
          element.state.width,
          element.state.height,
        );
      }
    });

    if (this.state.selected) {
      ctx.strokeStyle = "#39FF14";
      ctx.lineWidth = 0.007 * canvas.width;
      ctx.strokeRect(0, 0, canvas.width, canvas.height);
    }

    const canvasWidth = canvas.width;
    const middleX = canvasWidth / 2;

    const isCentered = this.elements.some((element) => {
      const objCenterX = element.properties.x;
      return (
        Math.abs(objCenterX - middleX) < 0.007 * canvas.width &&
        element.state.dragging
      );
    });

    if (isCentered) {
      ctx.strokeStyle = "#39FF14";
      ctx.lineWidth = 0.007 * canvas.width;
      const middleX = canvas.width / 2;
      ctx.beginPath();
      ctx.moveTo(middleX, 0);
      ctx.lineTo(middleX, canvas.height);
      ctx.stroke();
      ctx.setLineDash([]);
    }
  }

  makeUnique() {
    const canvas = this.ref.current;
    if (!canvas) return;
    const ctx = canvas.getContext("2d");
    if (!ctx) return;
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const randomX = Math.floor(Math.random() * canvas.width);
    const randomY = Math.floor(Math.random() * canvas.height);
    const index = (randomY * canvas.width + randomX) * 4;
    imageData.data[index] = 0;
    imageData.data[index + 1] = 0;
    imageData.data[index + 2] = 0;
    imageData.data[index + 3] = 255;
    ctx.putImageData(imageData, 0, 0);
  }

  changeImages() {
    const changeImagePromises = this.elements
      .filter(isImageElement)
      .map((element) => element.changeImage());
    return Promise.all(changeImagePromises);
  }

  setProperty<T extends keyof CanvasElementProperties>(
    key: T,
    value: CanvasElementProperties[T],
  ) {
    this.properties[key] = value;
  }
}
