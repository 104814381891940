import React, { forwardRef, useEffect, useLayoutEffect, useState } from "react";
import { Property } from "csstype";
import { isTextElement } from "../util/guards";
import { CanvasElement } from "../elements/CanvasElement";
import useEditorStore from "../hooks/useEditorStore";
import { CanvasRef } from "../../../types";

interface CanvasProps
  extends Omit<
    React.HTMLAttributes<HTMLDivElement>,
    "width" | "height" | "ref" | "onMouseMove" | "onMouseUp"
  > {
  canvasRef: CanvasRef;
  canvasElement: CanvasElement;
}

const getCursor = (currentAction: string | null): Property.Cursor => {
  return currentAction === "left" || currentAction === "right"
    ? "ew-resize"
    : currentAction === "top" || currentAction === "bottom"
      ? "ns-resize"
      : currentAction === "select"
        ? "pointer"
        : "move";
};

const Canvas = forwardRef<HTMLCanvasElement | null, CanvasProps>(
  ({ canvasRef, canvasElement, ...props }: CanvasProps, ref) => {
    const { renderCanvasOptions } = useEditorStore();
    const [, setRender] = useState(0);
    const [cursor, setCursor] = useState<Property.Cursor>("");
    const [scale, setScale] = useState(1);

    useLayoutEffect(() => {
      setScale(
        canvasElement.state.width /
          (canvasElement.ref.current?.getBoundingClientRect().width ?? 1),
      );
    }, [canvasElement.ref.current]);

    useEffect(() => {
      if (!document.body.customHandlers) {
        document.body.customHandlers = {};
      }

      if (!document.body.customHandlers.handeOutsideClick) {
        const handleEvent: EventListener = (event) => {
          const clickedItem = event.target;
          if (!(clickedItem instanceof HTMLElement)) {
            return;
          }

          const topBar = document.getElementById("top-bar");

          const isInputOrSelect =
            event.target instanceof HTMLInputElement ||
            event.target instanceof HTMLSelectElement ||
            event.target instanceof HTMLTextAreaElement ||
            event.target instanceof HTMLButtonElement;

          if (topBar?.contains(clickedItem) || isInputOrSelect) {
            return;
          }

          canvasRef.current.handleClickOutside(clickedItem);
          renderCanvasOptions();
        };

        document.body.addEventListener("click", handleEvent);

        document.body.customHandlers.handeOutsideClick = handleEvent;
      }

      return () => {
        if (!document.body.customHandlers) return;

        if (document.body.customHandlers.handeOutsideClick) {
          document.body.removeEventListener(
            "click",
            document.body.customHandlers.handeOutsideClick,
          );
          delete document.body.customHandlers.handeOutsideClick;
        }
      };
    }, []);

    const handleMouseDown = (e: React.MouseEvent<HTMLCanvasElement>) => {
      const canvas = canvasElement.ref.current;
      if (!canvas) return;

      const ctx = canvas.getContext("2d");
      if (!ctx) return;

      const rect = canvas.getBoundingClientRect();
      const clickX = (e.clientX - rect.left) * scale;
      const clickY = (e.clientY - rect.top) * scale;

      const selectMultiple = e.ctrlKey || e.shiftKey || e.metaKey;

      canvasRef.current.handleMouseDown(clickX, clickY, selectMultiple);
      renderCanvasOptions();
    };

    const handleMouseMove = (e: React.MouseEvent<HTMLCanvasElement>) => {
      const canvas = canvasElement.ref.current;
      if (!canvas) return;

      const rect = canvas.getBoundingClientRect();
      const mouseX = (e.clientX - rect.left) * scale;
      const mouseY = (e.clientY - rect.top) * scale;

      canvasRef.current.switchContext(canvasElement);

      if (e.buttons !== 1) {
        canvasRef.current.handleMouseMove(mouseX, mouseY);
      } else {
        canvasRef.current.handleMouseDrag(mouseX, mouseY);
      }
      setCursor(getCursor(canvasRef.current.currentAction));
    };

    const handleMouseUp = (e: React.MouseEvent<HTMLCanvasElement>) => {
      const canvas = canvasElement.ref.current;
      if (!canvas) return;

      const ctx = canvas.getContext("2d");
      if (!ctx) return;

      const rect = canvas.getBoundingClientRect();
      const clickX = (e.clientX - rect.left) * scale;
      const clickY = (e.clientY - rect.top) * scale;

      canvasRef.current.handleMouseUp(clickX, clickY);
      setCursor(getCursor(canvasRef.current.currentAction));
    };

    const textElements = canvasElement.elements
      .filter(isTextElement)
      .filter((element) => element.state.editable);

    return (
      <div className="flex relative" {...props}>
        <canvas
          className={`w-[300px] h-[${(16 / 9) * 300}px]`}
          style={{ cursor }}
          ref={ref}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
        />
        {textElements.map((textElement, i) => {
          return (
            <div
              className="flex items-center justify-center absolute transform -translate-x-1/2 -translate-y-1/2 cursor-text"
              key={i}
              style={{
                left: textElement.properties.x * (1 / scale),
                top: textElement.properties.y * (1 / scale),
                height: textElement.state.height * (1 / scale),
              }}
            >
              <textarea
                value={textElement.properties.text}
                wrap="off"
                rows={1}
                onChange={(e) => {
                  textElement.properties.text = e.target.value;
                  e.currentTarget.style.height = "auto";
                  e.currentTarget.style.height = `${e.currentTarget.scrollHeight}px`;
                  canvasElement.draw();
                  setRender((prev) => prev + 1);
                }}
                style={{
                  fontFamily: textElement.properties.fontFamily,
                  fontSize: textElement.properties.fontSize * (1 / scale),
                  lineHeight: 1.2,
                  width: textElement.state.width * (1 / scale),
                }}
                className={`leading-[1.2] align-middle text-center whitespace-pre outline-none overflow-hidden resize-none p-0 border-none text-transparent bg-transparent caret-black`}
                onFocus={(e) => {
                  e.currentTarget.style.height = "auto";
                  e.currentTarget.style.height = `${e.currentTarget.scrollHeight}px`;
                  e.target.setSelectionRange(0, e.target.value.length);
                }}
                onBlur={() => {
                  textElement.state.editable = false;
                }}
                autoFocus
              />
            </div>
          );
        })}
      </div>
    );
  },
);

Canvas.displayName = "Canvas";

export default Canvas;
