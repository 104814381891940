import { useEffect, useState } from "react";
import { DotsThreeVertical, File, Pencil, Trash } from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";
import { ProjectPage } from "@tikifu/shared/types";
import {
  deleteProject,
  getProjects,
  updateProject,
} from "../services/projects";

const Projects = () => {
  const [projectPage, setProjectPage] = useState<ProjectPage | null>(null);
  const [dialogPosition, setDialogPosition] = useState({ top: 0, left: 0 });
  const [selectedProjectId, setSelectedProjectId] = useState<number | null>(
    null,
  );
  const [editingProjectId, setEditingProjectId] = useState<number | null>(null);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const initialize = async () => {
      try {
        const projectPage = await getProjects({
          limit: 20,
          offset: 0,
        });
        setProjectPage(projectPage);
      } catch (e) {
        console.error("Error initializing projects", e);
      }
    };

    void initialize();
  }, []);

  const handleViewMore = async () => {
    if (!projectPage || isLoadingMore) return;

    setIsLoadingMore(true);
    try {
      const newProjectPage = await getProjects({
        limit: 20,
        offset: projectPage.data.length,
      });

      setProjectPage((prev) => {
        if (!prev) return prev;

        const data = [...prev.data, ...newProjectPage.data];

        return {
          ...newProjectPage,
          data,
        };
      });
    } catch (e) {
      console.error("Error fetching more projects:", e);
    } finally {
      setIsLoadingMore(false);
    }
  };

  const handleDeleteProject = async () => {
    if (!selectedProjectId || !projectPage) return;

    const selectedProjectIndex = projectPage.data.findIndex(
      (project) => project.id === selectedProjectId,
    );
    const selectedProject = projectPage.data[selectedProjectIndex];

    setProjectPage((prev) => {
      if (!prev) return prev;

      const updatedData = prev.data.filter(
        (project) => project.id !== selectedProjectId,
      );

      return {
        ...prev,
        data: updatedData,
        total: prev.total - 1,
      };
    });

    setSelectedProjectId(null);

    try {
      await deleteProject(selectedProjectId);
    } catch (e) {
      console.error("Error deleting project", e);
      setProjectPage((prev) => {
        if (!prev) return prev;

        const updatedData = [...prev.data];
        updatedData.splice(selectedProjectIndex, 0, selectedProject);

        return {
          ...prev,
          data: updatedData,
          total: prev.total + 1,
        };
      });
    }
  };

  const handleEdit = () => {
    if (!selectedProjectId) return;

    setEditingProjectId(selectedProjectId);
    setSelectedProjectId(null);
  };

  const handleBlur = async (e: React.FocusEvent<HTMLInputElement>) => {
    if (!editingProjectId || !projectPage) return;

    const editingProject = projectPage.data.find(
      (project) => project.id === editingProjectId,
    );

    if (!editingProject) return;

    const editedProject = { ...editingProject, name: e.target.value };

    setProjectPage((prev) =>
      prev
        ? {
            ...prev,
            data: prev.data.map((project) =>
              project.id === editingProject.id ? editedProject : project,
            ),
          }
        : prev,
    );

    setEditingProjectId(null);

    try {
      await updateProject(editingProject.id, { name: e.target.value });
    } catch (e) {
      console.error("Error updating project", e);
      setProjectPage((prev) =>
        prev
          ? {
              ...prev,
              data: prev.data.map((project) =>
                project.id === editingProject.id ? editingProject : project,
              ),
            }
          : prev,
      );
    }
  };

  const handleDotsClick = (event: React.MouseEvent, projectId: number) => {
    event.stopPropagation();
    const target = event.currentTarget as HTMLElement;
    const rect = target.getBoundingClientRect();

    setDialogPosition({
      top: rect.bottom + window.scrollY - 5,
      left: rect.left + window.scrollX + 5,
    });
    setSelectedProjectId(projectId);
  };

  const handleOutsideClick = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    if (!target.closest(".dots")) {
      setSelectedProjectId(null);
    }
  };

  useEffect(() => {
    if (selectedProjectId) {
      document.addEventListener("click", handleOutsideClick);
    } else {
      document.removeEventListener("click", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [selectedProjectId]);

  return (
    <>
      <div
        className={`pl-5 mt-4 pt-5 pr-5 rounded-2xl bg-[#111111] flex flex-col grow text-white ${projectPage && !projectPage.data.length ? "items-center justify-center" : ""}`}
      >
        {projectPage &&
          (projectPage.data.length == 0 ? (
            <p className="text-lg text-center text-white">
              No projects available. Click &quot;New Project&quot; to create a
              new one.
            </p>
          ) : (
            <div className="flex flex-col">
              <span className="mb-3 ml-2">Name</span>
              {projectPage.data.map((project, index) => (
                <div
                  key={index}
                  role="button"
                  tabIndex={0}
                  className={`flex items-center grow p-4 justify-between border-[0.5px] border-[#333333] border-r-0 border-l-0 rounded-none py-1 ${index !== projectPage.data.length - 1 ? "border-b-0" : ""} ${project.id === selectedProjectId ? "bg-sky-900" : "hover:bg-[#222222]"}`}
                  onClick={() => {
                    navigate(`${project.id}`);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.key === "Space") {
                      e.preventDefault();
                      navigate(`${project.id}`);
                    }
                  }}
                >
                  <div className="flex items-center">
                    <File className="mr-3" size={24} />
                    {editingProjectId === project.id ? (
                      <input
                        autoFocus
                        type="text"
                        defaultValue={project.name}
                        className="bg-transparent focus:outline-none leading-8"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        onFocus={(e) => {
                          e.target.select();
                        }}
                        onBlur={(e) => void handleBlur(e)}
                      />
                    ) : (
                      <span className="truncate py-1">{project.name}</span>
                    )}
                  </div>
                  <button
                    onClick={(e) => {
                      handleDotsClick(e, project.id);
                    }}
                    className={`dots p-1 rounded-full hover:bg-[#3a3a3a] ${project.id === selectedProjectId ? "bg-sky-900 hover:bg-sky-700" : ""}`}
                  >
                    <DotsThreeVertical size={24} />
                  </button>
                </div>
              ))}
              {projectPage.total > projectPage.data.length && (
                <button
                  className="mt-3 px-2 py-1 hover:bg-[#222222] rounded-xl"
                  onClick={() => void handleViewMore()}
                  disabled={isLoadingMore}
                >
                  {isLoadingMore ? "Loading..." : "View more"}
                </button>
              )}
            </div>
          ))}

        {selectedProjectId && (
          <div
            style={{
              top: dialogPosition.top,
              left: dialogPosition.left,
            }}
            className="absolute shadow-lg rounded py-2 -translate-x-full dots whitespace-nowrap bg-[#222222]"
          >
            <button
              className="flex flex-row w-full items-center gap-2 hover:bg-[#3a3a3a] py-1 px-3"
              onClick={() => void handleDeleteProject()}
            >
              <Trash size={20} />
              <span>Delete project</span>
            </button>
            <button
              className="flex flex-row w-full items-center gap-2 hover:bg-[#3a3a3a] py-1 px-3"
              onClick={handleEdit}
            >
              <Pencil size={20} />
              <span>Edit name</span>
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default Projects;
