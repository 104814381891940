import { useState, useEffect, useRef, cloneElement, ReactElement } from "react";
import { CaretDown } from "@phosphor-icons/react";
import { CaretUp } from "@phosphor-icons/react/dist/ssr";
import Button from "./Button";
import Tooltip from "./Tooltip";

interface DropdownProps {
  options: string[];
  ignores?: string[];
  onChange: (option: string) => void;
  defaultValue?: string;
  className?: string;
  direction?: "up" | "down";
  icon?: ReactElement;
  tooltipText?: string;
  tooltipPosition?: "top" | "bottom";
}

const Dropdown = ({
  options,
  ignores = [],
  onChange,
  defaultValue,
  className = "",
  direction = "down",
  icon,
  tooltipText = "",
  tooltipPosition = "bottom",
}: DropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentValue, setCurrentValue] = useState(defaultValue);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (option: string) => {
    onChange(option);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const iconWithOnClick = icon
    ? cloneElement(icon, { onClick: handleToggle })
    : null;

  const renderButton = () =>
    icon ? (
      <div
        role="presentation"
        onClick={handleToggle}
        className="cursor-pointer"
      >
        {iconWithOnClick}
      </div>
    ) : (
      <Button
        color="white"
        className={`flex flex-row gap-2 items-center justify-between w-full`}
        onClick={handleToggle}
      >
        {currentValue}
        {direction === "down" ? (
          isOpen ? (
            <CaretUp size={16} />
          ) : (
            <CaretDown size={16} />
          )
        ) : isOpen ? (
          <CaretDown size={16} />
        ) : (
          <CaretUp size={16} />
        )}
      </Button>
    );

  return (
    <div
      className={`relative flex items-center justify-center ${className}`}
      ref={dropdownRef}
    >
      {tooltipText ? (
        <Tooltip
          disabled={isOpen}
          text={tooltipText}
          position={tooltipPosition}
        >
          {renderButton()}
        </Tooltip>
      ) : (
        renderButton()
      )}
      {isOpen && (
        <div
          className={`absolute shadow-lg rounded left-0 w-fit bg-[#292929] z-10 py-2 ${
            direction === "up" ? "bottom-full mb-2" : "top-full mt-2"
          }`}
        >
          {options.map((option, index) => (
            <button
              key={index}
              className="w-full py-1 px-3 text-left text-white hover:bg-sky-900 hover:text-white transition-colors duration-200"
              onClick={() => {
                if (!ignores.includes(option)) {
                  setCurrentValue(option);
                }
                handleSelect(option);
              }}
            >
              {option}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
