import React, { DetailedHTMLProps, useEffect, useState } from "react";
import Tooltip from "./Tooltip"; // Assuming Tooltip is in the same directory

interface NumberInputProps
  extends Omit<
    DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    "onChange"
  > {
  min?: number;
  max?: number;
  step?: number;
  defaultValue: number;
  onChange: (value: number) => void;
  minusTooltip?: string;
  plusTooltip?: string;
  inputTooltip?: string;
  decimals?: number;
}

const NumberInput: React.FC<NumberInputProps> = ({
  min = 0,
  max = 100,
  step = 1,
  defaultValue,
  onChange,
  minusTooltip = "Decrease",
  plusTooltip = "Increase",
  inputTooltip = "Enter a number",
  decimals = 1,
  ...props
}) => {
  const [value, setValue] = useState<string>(defaultValue.toFixed(decimals));

  useEffect(() => {
    setValue(defaultValue.toFixed(decimals));
  }, [defaultValue]);

  const handleChange = (newValue: number) => {
    if (newValue >= min && newValue <= max) {
      const formattedValue = newValue.toFixed(decimals);
      setValue(formattedValue);
      onChange(Number(formattedValue));
    }
  };

  const handleMinusClick = () => {
    handleChange(parseFloat(value) - step);
  };

  const handlePlusClick = () => {
    handleChange(parseFloat(value) + step);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value.replace(",", ".");
    const numericValue = parseFloat(rawValue);

    if (!isNaN(numericValue)) {
      handleChange(numericValue);
    } else {
      setValue(rawValue);
    }
  };

  return (
    <div className="flex items-center border border-white rounded">
      <Tooltip position="bottom" text={minusTooltip}>
        <button
          onClick={handleMinusClick}
          className="flex items-center justify-center bg-transparent text-white w-8 h-8 transition-all duration-300 ease-in-out hover:bg-white hover:text-gray-900 focus:bg-white focus:text-gray-900"
        >
          −
        </button>
      </Tooltip>

      <Tooltip position="bottom" text={inputTooltip}>
        <input
          type="text"
          value={value}
          onChange={handleInputChange}
          className="flex items-center justify-center bg-transparent text-white w-9 py-1 text-center outline-none transition-all duration-300 ease-in-out hover:bg-white hover:text-gray-900 focus:bg-white focus:text-gray-900"
          min={min}
          max={max}
          step={step}
          style={{
            appearance: "textfield",
          }}
          {...props}
        />
      </Tooltip>
      <style>
        {`
          input[type=number]::-webkit-inner-spin-button, 
          input[type=number]::-webkit-outer-spin-button { 
            -webkit-appearance: none; 
            margin: 0; 
          }
          input[type=number] {
            -moz-appearance: textfield;
          }
        `}
      </style>

      <Tooltip position="bottom" text={plusTooltip}>
        <button
          onClick={handlePlusClick}
          className="flex items-center justify-center bg-transparent text-white w-8 h-8 transition-all duration-300 ease-in-out hover:bg-white hover:text-gray-900 focus:bg-white focus:text-gray-900"
        >
          +
        </button>
      </Tooltip>
    </div>
  );
};

export default NumberInput;
